import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-items"></a><h2>Psionic Items
    </h2>
    <a id="psionic-armor-and-shields"></a><h3>ARMOR AND SHIELDS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>{`In general, psionic armor and shields follow the rules for the
equivalent magic items, as far as enhancement bonuses, stacking, and
other base abilities are concerned. An enhancement bonus is treated the
same way regardless of whether magic or psionics created it.`}</p>
    <p>Psionic armor and shields differ from standard and magical
equivalents in that psionic armor and shields incorporate one or more
crystals into their construction. Particularly powerful armor and
shields may be entirely composed of brightly hued or transparent
crystals.</p>
    <a id="armor-and-shield-special-qualities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Roll d%. An 01 result indicates the item is intelligent, an 02-31
result indicates that the command thought (if any) is psionically
imprinted and whispers itself into the user&#8217;s mind on acquisition, and
32-100 indicates no special qualities.</p>
    <p>Intelligent items have extra abilities and sometimes also
extraordinary powers and special purposes. Substitute manifesting for
spellcasting as required.
    </p>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><a id="table-psionic-armor-special-abilities"></a><b>Table: Psionic
Armor Special Abilities</b>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>Minor</th>
                  <th style={{
                    "width": "15%"
                  }}>Medium</th>
                  <th style={{
                    "width": "15%"
                  }}>Major</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price Modifier</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30</td>
                  <td>01-07</td>
                  <td>01-03</td>
                  <td>Quickness
                  </td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>31-74</td>
                  <td>08-30</td>
                  <td>04-05</td>
                  <td>Landing</td>
                  <td>+4,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>75-80</td>
                  <td>31-46</td>
                  <td>06-10</td>
                  <td>Floating</td>
                  <td>+4,000 gp</td>
                </tr>
                <tr>
                  <td>81-96</td>
                  <td>47-55</td>
                  <td>11-20</td>
                  <td>Linked</td>
                  <td>+6,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>97</td>
                  <td>56-66</td>
                  <td>21-30</td>
                  <td>Seeing
                  </td>
                  <td>+6,000 gp</td>
                </tr>
                <tr>
                  <td>98</td>
                  <td>67-75</td>
                  <td>31-40</td>
                  <td>Power resistance (13)</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>99</td>
                  <td>76-83</td>
                  <td>41-50</td>
                  <td>Ectoplasmic</td>
                  <td>+10,800 gp</td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>84-89</td>
                  <td>51-60</td>
                  <td>Gleaming</td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>90-94
                  </td>
                  <td>61-70
                  </td>
                  <td>Power resistance (15)
                  </td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>95-98
                  </td>
                  <td>71-77
                  </td>
                  <td>Vanishing</td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>99
                  </td>
                  <td>78-83
                  </td>
                  <td>Mindarmor</td>
                  <td>+24,000 gp</td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>84-89
                  </td>
                  <td>Power resistance (17)</td>
                  <td>+4 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>90-94
                  </td>
                  <td>Radiant </td>
                  <td>+4 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>95-96
                  </td>
                  <td>Aporter</td>
                  <td>+40,320 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>97-98
                  </td>
                  <td>Power resistance (19)</td>
                  <td>+5 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>99
                  </td>
                  <td>Phasing</td>
                  <td>+65,520 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>100
                  </td>
                  <td>100
                  </td>
                  <td>100
                  </td>
                  <td>Roll twice again<sup>2</sup></td>
                  <td>-
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add
to enhancement bonus to determine total market price.&nbsp;&nbsp;&nbsp;
                    </span></td>
                </tr>
                <tr>
                  <td colSpan="5">2 <i>If you roll a special ability
twice, only one counts. If you roll two
versions of the same special ability, use the better.</i></td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "2%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47%"
          }}><span style={{
              "fontWeight": "bold"
            }}><a id="table-psionic-shield-special-abilities"></a>Table: Psionic Shield Special
Abilities&nbsp;&nbsp;&nbsp; </span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>Minor</th>
                  <th style={{
                    "width": "15%"
                  }}>Medium</th>
                  <th style={{
                    "width": "15%"
                  }}>Major</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price Modifier</th>
                </tr>
                <tr className="odd-row">
                  <td>01-35
                  </td>
                  <td>01-05
                  </td>
                  <td>01-03
                  </td>
                  <td>Heartening
                  </td>
                  <td>+720 gp </td>
                </tr>
                <tr>
                  <td>36-60
                  </td>
                  <td>06-10
                  </td>
                  <td>04-05
                  </td>
                  <td>Ranged </td>
                  <td>+1 bonus<sup>1</sup> </td>
                </tr>
                <tr className="odd-row">
                  <td>61-89
                  </td>
                  <td>11-15
                  </td>
                  <td>06-10
                  </td>
                  <td>Linked </td>
                  <td>+6,000 gp </td>
                </tr>
                <tr>
                  <td>90-97
                  </td>
                  <td>16-35
                  </td>
                  <td>11-24
                  </td>
                  <td>Power resistance (13) </td>
                  <td>+2 bonus<sup>1</sup> </td>
                </tr>
                <tr className="odd-row">
                  <td>98
                  </td>
                  <td>36-58
                  </td>
                  <td>25-34
                  </td>
                  <td>Manifester </td>
                  <td>+10,800 gp </td>
                </tr>
                <tr>
                  <td>99
                  </td>
                  <td>69-88
                  </td>
                  <td>35-44
                  </td>
                  <td>Averter </td>
                  <td>+12,960 gp </td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>89-92
                  </td>
                  <td>45-54
                  </td>
                  <td>Vanishing </td>
                  <td>+3 bonus<sup>1</sup> </td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>93-96
                  </td>
                  <td>55-64
                  </td>
                  <td>Power resistance (15) </td>
                  <td>+3 bonus<sup>1</sup> </td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>97-98
                  </td>
                  <td>65-74
                  </td>
                  <td>Wall </td>
                  <td>+20,160 gp </td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>99
                  </td>
                  <td>75-81
                  </td>
                  <td>Mindarmor </td>
                  <td>+24,000 gp </td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>82-88
                  </td>
                  <td>Power
resistance (17) </td>
                  <td>+4 bonus<sup>1</sup> </td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>89-93
                  </td>
                  <td>Aporter </td>
                  <td>+40,320 gp </td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>94-96
                  </td>
                  <td>Time
buttress </td>
                  <td>+5 bonus<sup>1</sup> </td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>-
                  </td>
                  <td>97-99
                  </td>
                  <td>Power
resistance (19) </td>
                  <td>+5 bonus<sup>1</sup> </td>
                </tr>
                <tr className="odd-row">
                  <td>100
                  </td>
                  <td>100
                  </td>
                  <td>100
                  </td>
                  <td>Roll
twice again<sup>2</sup> </td>
                  <td>-
                  </td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="5">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add to enhancement bonus to determine
total market
price.</span>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="5">2 <span style={{
                      "fontStyle": "italic"
                    }}>If you roll a special
ability twice, only one counts. If you roll two
versions of the same special ability, use the better.&nbsp; 
                    </span></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psionic-armor-and-shield-special-ability-descriptions"></a><h6>Psionic Armor and Shield Special Ability Descriptions</h6>
Most psionic armor and shields have only enhancement bonuses. Such
items can also have one or more of the special abilities detailed
below. Armor or a shield with a special ability must have at least a +1
enhancement bonus. 
    <a id="aporter"></a><p><span style={{
        "fontWeight": "bold"
      }}>Aporter:</span> As a standard
action, a suit of aporter armor or an
aporter
shield transports the wearer and her equipment to any spot within 800
feet that she can visualize or specify, as the <a href="psionicPowersDtoF.html#dimension-door-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic dimension door</a>
power. The armor or shield can transport the wearer in this fashion
twice per day.
      <p>{`Moderate psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
10th; Craft Psionic Arms and Armor,
psionic dimension door; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+40,320 gp.`}</p></p>
    <a id="averter"></a><p><span style={{
        "fontWeight": "bold"
      }}>Averter:</span> On command,
up to three times per day, any creature
to whom
the wearer presents an averter shield must succeed on a DC 14 Will save
or be overcome by a powerful aversion to the wielder (actually the
shield), and will not approach within 30 feet. This is a mind-affecting
compulsion effect, as the <a href="psionicPowersAtoC.html#aversion" style={{
        "color": "rgb(87, 158, 182)"
      }}>aversion</a> power.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
aversion; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+12,960 gp.`}</p></p>
    <a id="ectoplasmic"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ectoplasmic:</span> As a
standard action, ectoplasmic armor converts
itself,
its wearer, and all the wearer&#8217;s equipment into ectoplasmic form for up
to 5 minutes once per day, as the power of the same name. In this
semisolid state, the wearer gains damage reduction 10/psionics.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Psionic Arms and Armor,
ectoplasmic form; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+10,800 gp.`}</p></p>
    <a id="floating"></a><p><span style={{
        "fontWeight": "bold"
      }}>Floating:</span> This kind of
armor is psionically buoyant in water
or a
similar liquid, negating the normal penalty for wearing armor when
making <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
checks. Additionally, it grants a +4 circumstance bonus on
Swim checks.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
4th; Craft Psionic Arms and Armor,
float; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +4,000 gp.`}</p></p>
    <a id="gleaming"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gleaming:</span> This kind of
armor is usually made of crystal,
though it
doesn&#8217;t have to be. Gleams and flashes from the armor give the wearer
and his armor a &#8220;fuzzy&#8221; appearance, granting the wearer concealment.
      <p>{`Faint metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
concealing
amorpha; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3 bonus.`}</p></p>
    <a id="heartening"></a><p><span style={{
        "fontWeight": "bold"
      }}>Heartening:</span> This kind
of shield grants the wearer up to 5
temporary hit
points per day on command. These temporary hit points fade after 4
minutes. The wearer can activate this power as an immediate action at
any time.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
4th; Craft Psionic Arms and Armor,
vigor; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +720 gp.`}</p></p>
    <a id="landing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Landing:</span> A suit of
armor with this capability allows the
wearer to
ignore any damage dealt by the first 60 feet of a fall. Regardless of
the height of a fall, the wearer always lands on her feet.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
4th; Craft Psionic Arms and Armor,
catfall; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +4,000 gp.`}</p></p>
    <a id="linked"></a><p><span style={{
        "fontWeight": "bold"
      }}>Linked:</span> This kind of
armor or shield allows the wearer to form
a
telepathic bond with other wearers of linked armor or shields within 10
miles. This ability is otherwise similar to the <a href="psionicPowersGtoP.html#mindlink" style={{
        "color": "rgb(87, 158, 182)"
      }}>mindlink</a> power.
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 6th;
Craft Psionic Arms and Armor,
mindlink; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +6,000 gp.`}</p></p>
    <a id="manifester-shield"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifester:</span> This kind
of shield generates 3 power points once
per day
that the wearer can use when manifesting a power he knows. These power
points must all be used on the same power. As usual, a psionic
character cannot pay a power&#8217;s cost with power points from more than
one source, so the power points in the shield must be used for discrete
manifestations.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
6th; Craft Psionic Arms and Armor,
knowledge of any 2nd-level power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+10,800 gp.`}</p></p>
    <a id="mindarmor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mindarmor:</span> This kind
of armor or shield grants the wearer a +3
insight
bonus on Will saving throws to resist all mind-affecting and/or
compulsion powers.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
empty mind; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +24,000 gp.`}</p></p>
    <a id="phasing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Phasing:</span> The wearer of
this kind of armor can move through
wooden,
plaster, or stone walls, but not other materials. The wearer can call
on this special ability as a standard action. When the phasing ability
is active, the wearer can pass through a wall or some other kind of
appropriate object for a total distance of 60 feet per day (see below),
breaking this distance up into several smaller passages or one long
one, as desired. A wearer who exceeds this daily distance limit while
inside solid material is ejected from the material at the point of
entry, ending up prone in front of the now impassable barrier.
      <p>{`Phasing through a wall that separates two adjacent squares on
the grid
counts as 5 feet of distance. Phasing through a wall or barrier of any
greater thickness counts as a distance equal to the barrier`}{`’`}{`s thickness
plus 5 feet.`}</p>
      <p>{`Strong psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
13th; Craft Psionic Arms and Armor,
psionic
phase door; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +65,520 gp.`}</p></p>
    <a id="power-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Power Resistance:</span> This
kind of armor or shield grants the
wearer power
resistance while it is worn. The power resistance can be 13, 15, 17, or
19, depending on the amount that was built into the armor or shield.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
9th; Craft Psionic Arms and Armor,
power
resistance; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus
(PR 13); +3 bonus (PR 15); +4 bonus
(PR 17);
or +5 bonus (PR 19).`}</p></p>
    <a id="quickness"></a><p><span style={{
        "fontWeight": "bold"
      }}>Quickness:</span> This kind
of armor increases the wearer&#8217;s speed by
5 feet.
Thus, a character whose normal speed in armor is 20 feet moves 25 feet
in armor of quickness.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
4th; Craft Psionic Arms and Armor,
burst; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1 bonus.`}</p></p>
    <a id="radiant"></a><p><span style={{
        "fontWeight": "bold"
      }}>Radiant:</span> The wearer of
this kind of armor gains <a href="monsterTypes.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>resistance</a> 10
against
energy attacks (acid, cold, electricity, fire, or sonic). The armor
absorbs the first 10 points of damage dealt by any such attack, and
this absorption causes it to radiate light for a number of rounds equal
to the points of damage absorbed. This light is sufficient to
illuminate a 60-foot-radius area.
      <p>{`If the armor absorbs more damage while it is radiating light,
the newer
radiant effect overlaps (does not stack with) the effect that was
already in place.`}</p>
      <p>{`Moderate psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
9th; Craft Psionic Arms and Armor;
energy
adaptation; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +4 bonus.`}</p></p>
    <a id="ranged"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ranged:</span> The wielder of
a ranged shield can throw it in combat,
with a
range increment of 30 feet. While in the air, the shield is treated in
all ways as a ranged weapon and cannot be blocked or grabbed except by
those with appropriate feats. No matter the size of the wielder, a
buckler or light shield deals 1d6 points of damage and a heavy one 1d8
points. (A tower shield cannot be created with this special ability.)
The wielder&#8217;s Strength modifier and the shield&#8217;s enhancement bonus add
to the base damage.
      <p>{`A ranged shield flies through the air back to the creature
that threw
it. It returns to the wielder just before the creature`}{`’`}{`s next turn (and
is therefore ready to use again in that turn).`}</p>
      <p>{`Catching a ranged shield when it comes back is a free action.
If the
wielder can`}{`’`}{`t catch it, or if the wielder has moved since throwing it,
the shield drops to the ground in the square from which it was thrown.`}</p>
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
far hand; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1 bonus.`}</p></p>
    <a id="seeing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Seeing:</span> This kind of
armor grants a wider than normal field of
vision,
so that opponents flanking the wearer gain only a +1 bonus on their
attack rolls instead of +2 (rogues still get their full sneak attack
damage because the wearer is still flanked). The wearer gains a +1
enhancement bonus on <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> checks but takes a -2
penalty on saves
against gaze attacks.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
ubiquitous
vision; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +6,000 gp.`}</p></p>
    <a id="time-buttress"></a><p><span style={{
        "fontWeight": "bold"
      }}>Time Buttress:</span> This
kind of shield gives the wielder a chance
to avoid
telling blows by using time itself as a shield. Once per day, the
wielder can use <a href="psionicPowersQtoW.html#timeless-body" style={{
        "color": "rgb(87, 158, 182)"
      }}>timeless body</a> as though
manifesting the power.
      <p>{`Strong psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
17th; Craft Psionic Arms and Armor,
timeless
body; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +5 bonus.`}</p></p>
    <a id="vanishing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Vanishing:</span> On command,
this suit of armor or shield renders
its wearer
and all the wearer&#8217;s equipment invisible to the minds of others, as if
he had manifested the power <a href="psionicPowersAtoC.html#cloud-mind" style={{
        "color": "rgb(87, 158, 182)"
      }}>cloud mind</a>. The wearer can use
this ability
twice per day.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor,
cloud mind; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3 bonus.`}</p></p>
    <a id="wall"></a><p><span style={{
        "fontWeight": "bold"
      }}>Wall:</span> As a standard
action once per day, the wielder can drop
this kind
of shield at his feet and command a <a href="psionicPowersQtoW.html#wall-of-ectoplasm" style={{
        "color": "rgb(87, 158, 182)"
      }}>wall of ectoplasm</a> (as the power)
to
come into being, with the shield as the point of origin for the effect.
This effect forms a wall whose area is up to twelve 10-foot squares or
a sphere or hemisphere with a radius of up to 12 feet. The wall
dissipates after 7 minutes, or sooner if the wielder of the shield
reclaims it (thus dismissing the effect).
      <p>{`Strong metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor,
wall of
ectoplasm; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +20,160 gp. `}</p></p>
    <a id="psionic-weapons"></a><h3>WEAPONS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>{`Although they generally follow the rules for magic weapons, psionic
weapons differ from mundane and magic weapons in their design. Most
possess some crystal components, and some are composed entirely of
crystal. Many also sport intricate traceries along the blade or haft,
which flicker now and then as if channeling flows of psychic energy.`}</p>
    <p>Psionic weapons have enhancement bonuses ranging from +1 to
+5. They apply these bonuses on both attack and damage rolls when used
in combat. This enhancement bonus is effective for the purpose of
overcoming creatures with damage resistance that is penetrated by
magic. All psionic weapons are also masterwork weapons, but their
masterwork bonus on attack rolls does not stack with their enhancement
bonus on attack rolls.</p>
    <p>Weapons come in two basic categories: melee and ranged. Some
of the weapons listed as melee weapons can also be used as ranged
weapons. In this case, their enhancement bonus applies to either type
of attack. </p>
    <p>In addition to enhancement bonuses, psionic weapons can also
have the special abilities detailed here. A weapon with a special
ability must have at least a +1 enhancement bonus.</p>
    <p>The rules for manifester level for weapons (called caster
level), additional damage dice, ranged weapons and ammunition,
ammunition and breakage, hardness and hit points, critical hits, and
weapons for unusually sized creatures for psionic weapons are the same
as for magic weapons. Psionic weapons do not have the chance to
generate light (though some may be mentally audible; see below).</p>
    <a id="mentally-audible"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mentally Audible:</span>
Fully 30% of psionic weapons telepathically mutter, croon, recite
battle poems, scream, or produce other mental &#8220;noises&#8221; when first
drawn, at first blood, or when they slay a significant enemy. Such a
weapon cannot be concealed from creatures within 15 feet when it is
drawn, nor can its mental &#8220;soundtrack&#8221; be curbed.</p>
    <a id="activation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Activation:</span> Usually a
character benefits from a psionic weapon in the same way a character
benefits from a mundane weapon - by attacking with it. If a weapon has
a
special ability that the user needs to activate, then the user usually
needs to project a command thought (a standard action).
    </p>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><a id="table-psionic-melee-weapon-special-abilities"></a><b>Table: Psionic
Melee Weapon Special Abilities</b>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>Minor</th>
                  <th style={{
                    "width": "15%"
                  }}>Medium</th>
                  <th style={{
                    "width": "15%"
                  }}>Major</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price Modifier</th>
                </tr>
                <tr className="odd-row">
                  <td>1-15
                  </td>
                  <td>01-10
                  </td>
                  <td>-
                  </td>
                  <td>Psychokinetic </td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>16-45
                  </td>
                  <td>11-25
                  </td>
                  <td>01-09
                  </td>
                  <td>Dissipater</td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>46-55
                  </td>
                  <td>26-30
                  </td>
                  <td>10-12
                  </td>
                  <td>Lucky</td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>56-70
                  </td>
                  <td>31-40
                  </td>
                  <td>13-17
                  </td>
                  <td>Sundering</td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>71-80
                  </td>
                  <td>41-46
                  </td>
                  <td>18-21
                  </td>
                  <td>Power storing</td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>81-99
                  </td>
                  <td>51-56
                  </td>
                  <td>22-30
                  </td>
                  <td>Collision </td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>57-65
                  </td>
                  <td>31-37
                  </td>
                  <td>Mindcrusher</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>66-71
                  </td>
                  <td>38-41
                  </td>
                  <td>Psychokinetic burst</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>72-75
                  </td>
                  <td>42-49
                  </td>
                  <td>Suppression</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>76-78
                  </td>
                  <td>50-58
                  </td>
                  <td>Psibane</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>79-81
                  </td>
                  <td>59-61
                  </td>
                  <td> Parrying
                  </td>
                  <td>+8,000 gp</td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>82-85
                  </td>
                  <td>62-65
                  </td>
                  <td>Manifester</td>
                  <td>+16,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>86-88
                  </td>
                  <td>66-71
                  </td>
                  <td>Bodyfeeder
                  </td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>89-91
                  </td>
                  <td>72-77
                  </td>
                  <td>Mindfeeder</td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>92-93
                  </td>
                  <td>78-83
                  </td>
                  <td>Soulbreaker</td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>-
                  </td>
                  <td>94
                  </td>
                  <td>84-86
                  </td>
                  <td>Psychic
                  </td>
                  <td>+35,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>-
                  </td>
                  <td>95
                  </td>
                  <td>87-90
                  </td>
                  <td>Coup de grace</td>
                  <td>+5 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>100
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>96-100
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>91-100
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Roll again twice<sup>2</sup></td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>-
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add
to enhancement bonus to determine total market price.</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">2 <span style={{
                      "fontStyle": "italic"
                    }}>Reroll
if you get a duplicate special ability, an ability
incompatible with an ability that you&#8217;ve already rolled, or if the
extra ability puts you over the +10 limit. A weapon&#8217;s enhancement bonus
and special ability bonus equivalents can&#8217;t total more than +10.</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "2%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47%"
          }}><span style={{
              "fontWeight": "bold"
            }}><a id="table-psionic-ranged-wreapon-special-abilities"></a>Table: Psionic Ranged Weapon Special
Abilities</span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>Minor</th>
                  <th style={{
                    "width": "15%"
                  }}>Medium</th>
                  <th style={{
                    "width": "15%"
                  }}>Major</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price Modifier</th>
                </tr>
                <tr className="odd-row">
                  <td>01-20
                  </td>
                  <td>01-15
                  </td>
                  <td>-
                  </td>
                  <td>Teleporting
                  </td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>21-40
                  </td>
                  <td>16-30
                  </td>
                  <td>-
                  </td>
                  <td>Psychokinetic</td>
                  <td>+1 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>41-100
                  </td>
                  <td>31-45
                  </td>
                  <td>01-30
                  </td>
                  <td>Collision
                  </td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>46-50
                  </td>
                  <td>31-40
                  </td>
                  <td>Suppression</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>
                  </td>
                  <td>51-66
                  </td>
                  <td>41-65
                  </td>
                  <td>Psychokinetic burst</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>67-74
                  </td>
                  <td>66-69
                  </td>
                  <td>Psibane</td>
                  <td>+2 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>
                  </td>
                  <td>75-78
                  </td>
                  <td>70-73
                  </td>
                  <td>Manifester </td>
                  <td>+16,000 gp</td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>79-82
                  </td>
                  <td>74-79
                  </td>
                  <td>Dislocator</td>
                  <td>+3 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>
                  </td>
                  <td>83-98
                  </td>
                  <td>80-90
                  </td>
                  <td>Great dislocator</td>
                  <td>+4 bonus<sup>1</sup></td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>-
                  </td>
                  <td>91-97
                  </td>
                  <td>Coup de grace</td>
                  <td>+5 bonus<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>
                  </td>
                  <td>99-100
                  </td>
                  <td>98-100
                  </td>
                  <td>Roll again twice<sup>2</sup></td>
                  <td>-
                  </td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="5">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add to enhancement bonus to determine
total market price.&nbsp;&nbsp;&nbsp; </span></td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="5">2 <span style={{
                      "fontStyle": "italic"
                    }}>Reroll if you get a
duplicate special ability, an ability
incompatible with an ability that you&#8217;ve already rolled, or if the
extra ability puts you over the +10 limit. A weapon&#8217;s enhancement bonus
and special ability bonus equivalents can&#8217;t total more than +10. </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
    </p>
    <a id="psionic-weapon-special-ability-descriptions"></a><h6>Psionic Weapon Special Ability Descriptions</h6>
Most psionic weapons have only enhancement bonuses. They can also have
the special abilities detailed here. A weapon with a special ability
must have at least a +1 enhancement bonus.
    <a id="bodyfeeder"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bodyfeeder:</span> All feeder
weapons have a special ability that functions
only upon scoring a successful critical hit. A bodyfeeder weapon grants
its wielder temporary hit points equal to the total damage dealt by a
successful critical hit. These temporary hit points last for 10
minutes. Thus, if the wielder of a bodyfeeder weapon successfully
scores a critical hit while the wielder still enjoys temporary hit
points from a previous critical hit, the wielder gains only the better
of the two values: either his current number of temporary hit points,
or the new influx of temporary hit points, whichever is higher.
      <p>{`Strong psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, claws
of the vampire; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3
bonus.`}</p></p>
    <a id="collision"></a><p><span style={{
        "fontWeight": "bold"
      }}>Collision:</span> Collision
weapons psionically increase their own mass at the
end point of each swing or shot. Such weapons deal an extra 5 points of
damage on each successful strike, in addition to the weapon&#8217;s
enhancement bonus. Bows, crossbows, and slings bestow the extra damage
upon their ammunition.
      <p>{`Moderate metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
10th; Craft Psionic Arms and Armor, matter
manipulation; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus.`}</p></p>
    <a id="coup-de-grace"></a><p><span style={{
        "fontWeight": "bold"
      }}>Coup de Grace:</span> Coup de
grace weapons are exceptionally dangerous. On a
successful critical hit, the foe must succeed on a DC 27 Will save or
be <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a> for 1 round. While
this ability does work on creatures
that are immune to extra damage from critical hits, it does not work on
creatures without an Intelligence score. Bows, crossbows, and slings
bestow this ability on their ammunition.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 19th;
Craft Psionic Arms and Armor, psionic
dominate; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +5 bonus.`}</p></p>
    <a id="dislocator"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dislocator:</span> The
wielder of this kind of weapon can attempt to dislocate
a designated foe up to three times per day. On a successful hit, the
foe must succeed on a DC 17 Will save or be <a href="psionicPowersQtoW.html#teleport-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>teleported</a> 1-100 miles in a
random direction. If the weapon misses, the use is wasted. Bows,
crossbows, and slings bestow this ability on their ammunition.
      <p>{`Strong psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, psionic
teleport; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3 bonus.`}</p></p>
    <a id="dissipater"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dissipater:</span> This kind
of weapon is devastating to creatures and objects
composed of or originally formed from ectoplasm (such as astral
constructs, walls of ectoplasm, creatures in ectoplasmic form, and
items created using the metacreativity discipline). Against qualifying
targets, a dissipater weapon ignores damage reduction and hardness, and
treats all successful hits as critical hits.
      <p>{`Strong metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, dismiss
ectoplasm; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1 bonus.`}</p></p>
    <a id="great-dislocator"></a><p><span style={{
        "fontWeight": "bold"
      }}>Great Dislocator:</span> The
wielder of this kind of weapon can attempt to
greatly dislocate a designated foe up to three times per day. On a
successful hit, the foe must succeed on a DC 20 Will save or be cast
into a random alternate plane of existence. If the weapon misses, the
use is wasted. Bows, crossbows, and slings bestow this ability upon
their ammunition.
      <p>{`Strong psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, psionic
plane shift; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +4 bonus.`}</p></p>
    <a id="lucky"></a><p><span style={{
        "fontWeight": "bold"
      }}>Lucky:</span> A lucky weapon
offers a second chance at success. Once per day,
the wielder can reroll a failed attack roll (whether a single attack or
one in a series of multiple attacks) as a free action. The rerolled
attack uses the same bonuses or penalties as the missed roll.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Psionic Arms and Armor, fate of
one; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1 bonus.`}</p></p>
    <a id="manifester-weapon"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifester:</span> This kind
of weapon generates 5 power points once per day
that the wearer can use when manifesting a power he knows. These power
points must all be used on the same power. As usual, a psionic
character cannot pay a power&#8217;s cost with power points from more than
one source, so the power points in the shield must be used for discrete
manifestations.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Psionic Arms and Armor,
knowledge of any 3rd-level power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+16,000 gp.`}</p></p>
    <a id="mindcrusher"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mindcrusher:</span> Any
psionic creature struck in combat by a mindcrusher
weapon loses a number of power points equal to half the amount of hit
point damage the weapon deals (only the base damage of the weapon
contributes to the power point loss; additional damage from high
Strength or other sources does not cause additional power point loss).
A psionic creature that is out of power points (or has none) must
succeed on a DC 17 Will save or take 1d2 points of Wisdom damage.
      <p>{`Strong psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, psychic
vampire; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus.`}</p></p>
    <a id="mindfeeder"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mindfeeder:</span> All feeder
weapons have a special ability that functions
only upon scoring a successful critical hit. Once per day, a mindfeeder
weapon grants
its wielder temporary power points equal to the total lethal damage
dealt by a
successful critical hit. The wielder must decide to use the mindfeeder
ability after successfully confirming a critical hit, but prior to
rolling critical hit damage. These temporary power points last for 10
minutes. The wielder may not exceed his natural maximum power points
per day using the mindfeeder ability. The wielder gains power points
even if the target has none
(effectively, its hit point damage is converted to power points).
Constructs and undead are not subject to mindfeeder weapons. As with
temporary hit points, temporary power points do not stack with each
other; they overlap. Thus, if a mindfeeder weapon successfully scores a
critical hit while the wielder still enjoys temporary power points from
a previous critical hit, the wielder gains only the better of the two
values: either her current number of temporary power points, or the new
influx of temporary power points, whichever is higher.
      <p>{`Strong psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Psionic Arms and Armor, psychic
vampire; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3 bonus.`}</p></p>
    <a id="parrying"></a><p><span style={{
        "fontWeight": "bold"
      }}>Parrying:</span> A parrying
weapon perceives an instant into the future, and
of its own accord resists melee and ranged attacks aimed at the
wielder, granting a +1 insight bonus to the wielder&#8217;s Armor Class. The
weapon is so adept at parrying that it even affects incoming powers,
granting the wielder a +1 insight bonus on saving throws. The bonuses
are granted whenever the wielder holds the weapon, even if flat-footed.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor, defensive
precognition; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +8,000 gp.`}</p></p>
    <a id="power-storing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Power Storing:</span> A power
storing weapon allows a manifester to store a
single targeted power of up to 5 power points in the weapon. (The power
must have a manifesting time of 1 standard action.) Any time the weapon
strikes a creature and the creature takes damage from it, the weapon
can immediately manifest the power on that creature as a swift action
if the wielder desires. (This ability is an exception to the rule that
manifesting a power from an item takes at least as long as manifesting
that power normally.) Once the power is manifested, the weapon is
empty, and a manifester can imbed any other targeted power of up to 5
power points into it. The weapon telepathically whispers to the wearer
the name of the power currently stored within it. A randomly generated
power storing weapon has a 50% chance to have a power stored in it
already.
      <p>{`Strong psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, creator
must be a manifester of at least 12th level; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1 bonus.`}</p></p>
    <a id="psibane"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psibane:</span> A psibane
weapon is crafted to oppose psionic beings. When
used against such creatures, its effective enhancement bonus is 2
higher than its actual enhancement bonus. It deals an extra 2d6 points
of damage against psionic opponents. It bestows one negative level on
any psionic creature attempting to wield it. This negative level
remains as long as the weapon is in hand and disappears when the weapon
is no longer wielded. This negative level never results in actual level
loss, but it cannot be overcome in any way while the weapon is wielded.
Psibane bows, crossbows, and slings bestow this ability upon their
ammunition.
      <p>{`Strong clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Psionic Arms and Armor, bend
reality; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus.`}</p></p>
    <a id="psychic"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychic:</span> A psychic
weapon&#8217;s power depends on its wielder. In the hands
of a nonpsionic creature, the weapon possesses the qualities of a
nonmagical, nonpsionic masterwork weapon. When wielded by a psionic
creature, this weapon has an enhancement bonus based on the wielder&#8217;s
current power point reserve, as shown on the following table. The
weapon&#8217;s enhancement bonus decreases as the wielder spends power
points, and it increases whenever the wielder gains enough power points
(by any means) to put his power point reserve into the next higher
category.</p>
    <a id="table-psychicweapon-enhancement-bonus"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "160px"
          }}>Power Point Reserve</th>
          <th>Enhancement
Bonus</th>
        </tr>
        <tr className="odd-row">
          <td>0-4
          </td>
          <td>+1
          </td>
        </tr>
        <tr>
          <td>5-29
          </td>
          <td>+2
          </td>
        </tr>
        <tr className="odd-row">
          <td>30-79
          </td>
          <td>+3
          </td>
        </tr>
        <tr>
          <td>80-129
          </td>
          <td>+4
          </td>
        </tr>
        <tr className="odd-row">
          <td>130 or higher
          </td>
          <td>+5
          </td>
        </tr>
      </tbody>
    </table>
    <p>Strong clairsentience; <span style={{
        "fontStyle": "italic"
      }}>ML</span>
17th; Craft Psionic Arms and Armor, reality
revision; <span style={{
        "fontStyle": "italic"
      }}>Price</span> +35,000.</p>
    <a id="psychokinetic"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychokinetic:</span> Upon
command, a psychokinetic weapon glows from the
inside with lethal psionic energy. The energy does not harm the hands
that hold the weapon. Such a weapon deals an extra 1d4 points of damage
on a successful hit. This extra damage is ectoplasmic in nature and is
not affected by damage reduction. Bows, crossbows, and slings bestow
this ability upon their ammunition.
      <p>{`Moderate psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
10th; Craft Psionic Arms and Armor,
concussion blast; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1
bonus.`}</p></p>
    <a id="psychokinetic-burst"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychokinetic Burst:</span>
This weapon functions as a psychokinetic weapon
that also releases a blast of destructive psionic energy upon scoring a
successful critical hit. In addition to the extra damage of the
psychokinetic ability (see above), a psychokinetic burst weapon deals
an extra 1d6 points of damage on a successful critical hit. If the
weapon&#8217;s critical multiplier is x3, add 2d6 points of extra damage
instead, and if the multiplier is x4, add 3d6 points of extra damage.
This extra damage is ectoplasmic in nature and is not affected by
damage reduction. Bows, crossbows, and slings bestow this ability upon
their ammunition.
      <p>{`Strong psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
12th; Craft Psionic Arms and Armor, concussion
blast; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus.`}</p></p>
    <a id="soulbreaker"></a><p><span style={{
        "fontWeight": "bold"
      }}>Soulbreaker:</span> This
weapon has a special ability that functions only upon
scoring a successful critical hit. On a successful critical hit, a
soulbreaker weapon bestows one negative level on the foe. One day after
being struck, if the negative levels have not been purged, the subject
must succeed on a DC 18 Fortitude save for each negative level or lose
a character level.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 12th;
Craft Psionic Arms and Armor, mindwipe; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +3 bonus.`}</p></p>
    <a id="sundering"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sundering:</span> This kind
of weapon allows a wielder to attack opponents&#8217;
weapons as if he had the <a href="featsAll.html#improved-sunder" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Sunder</a> feat.
      <p>{`Faint metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Psionic Arms and Armor, Great
Sunder, metaphysical weapon; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
+1 bonus.`}</p></p>
    <a id="suppression"></a><p><span style={{
        "fontWeight": "bold"
      }}>Suppression:</span> An
opponent or object struck by this kind of weapon is
subject to a targeted <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a> power. The
wielder makes a dispel
check (1d20 + 5 + manifester level, maximum +15) against a DC of 11 +
the manifester level of the power to be dispelled. Bows, crossbows, and
slings bestow this ability upon their ammunition, but can do so only
three times per day.
      <p>{`Moderate psychokinesis `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
10th; Craft Psionic Arms and Armor, dispel
psionics; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +2 bonus.`}</p></p>
    <a id="teleporting"></a><p><span style={{
        "fontWeight": "bold"
      }}>Teleporting:</span> This
ability can be imbedded only in weapons that can be
thrown. A teleporting weapon returns through the Astral Plane to the
creature that threw it. It teleports into the throwing creature&#8217;s empty
hand in the round following the round when it was thrown, just before
that creature&#8217;s turn. It is therefore ready to use again on that turn.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Psionic Arms and Armor, psionic
dimension door; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` +1
bonus.`}</p></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      